.Toastify__toast--success {
  background: #4aaf57 !important;
  border-radius: 16px !important;
}

.Toastify__toast--success::after {
  content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='32' width='32'><path d='M10.2267 2.6665H21.7867C26.3067 2.6665 29.3334 5.83984 29.3334 10.5598V21.4545C29.3334 26.1598 26.3067 29.3332 21.7867 29.3332H10.2267C5.70675 29.3332 2.66675 26.1598 2.66675 21.4545V10.5598C2.66675 5.83984 5.70675 2.6665 10.2267 2.6665ZM15.2399 19.9865L21.5733 13.6532C22.0266 13.1998 22.0266 12.4665 21.5733 11.9998C21.1199 11.5465 20.3733 11.5465 19.9199 11.9998L14.4133 17.5065L12.0799 15.1732C11.6266 14.7198 10.8799 14.7198 10.4266 15.1732C9.97325 15.6265 9.97325 16.3598 10.4266 16.8265L13.5999 19.9865C13.8266 20.2132 14.1199 20.3198 14.4133 20.3198C14.7199 20.3198 15.0133 20.2132 15.2399 19.9865Z' fill='white' /></svg>");
  position: absolute;
  left: 27px;
  padding-top: 10px !important;
}
.Toastify__toast--error {
  background: #f54336 !important;
  border-radius: 16px !important;
}

.Toastify__toast--error::after {
  content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='27' width='27'><path d='M8.22626 0.665527H19.7863C24.3063 0.665527 27.3329 3.83886 27.3329 8.55886V19.4535C27.3329 24.1602 24.3063 27.3322 19.7863 27.3322H8.22626C3.70626 27.3322 0.66626 24.1602 0.66626 19.4535V8.55886C0.66626 3.83886 3.70626 0.665527 8.22626 0.665527ZM18.0131 17.9988C18.4665 17.5468 18.4665 16.8135 18.0131 16.3601L15.6398 13.9868L18.0131 11.6121C18.4665 11.1601 18.4665 10.4135 18.0131 9.96014C17.5598 9.50547 16.8265 9.50547 16.3598 9.96014L13.9998 12.3321L11.6265 9.96014C11.1598 9.50547 10.4265 9.50547 9.97314 9.96014C9.5198 10.4135 9.5198 11.1601 9.97314 11.6121L12.3465 13.9868L9.97314 16.3468C9.5198 16.8135 9.5198 17.5468 9.97314 17.9988C10.1998 18.2255 10.5065 18.3468 10.7998 18.3468C11.1065 18.3468 11.3998 18.2255 11.6265 17.9988L13.9998 15.6401L16.3731 17.9988C16.5998 18.2401 16.8931 18.3468 17.1865 18.3468C17.4931 18.3468 17.7865 18.2255 18.0131 17.9988Z' fill='white' /></svg>");
  position: absolute;
  left: 27px;
  padding-top: 10px !important;
}

.Toastify__toast--info {
  background: #00a9f1 !important;
  border-radius: 16px !important;
}

.Toastify__toast--info::after {
  content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='32' width='32'><path d='M10.2267 2.66553H21.7867C26.3067 2.66553 29.3334 5.83886 29.3334 10.5589V21.4535C29.3334 26.1602 26.3067 29.3322 21.7867 29.3322H10.2267C5.70675 29.3322 2.66675 26.1602 2.66675 21.4535V10.5589C2.66675 5.83886 5.70675 2.66553 10.2267 2.66553ZM15.9865 12.0802C15.3598 12.0802 14.8398 11.5589 14.8398 10.9202C14.8398 10.2669 15.3598 9.74691 16.0132 9.74691C16.6532 9.74691 17.1732 10.2669 17.1732 10.9202C17.1732 11.5589 16.6532 12.0802 15.9865 12.0802ZM17.1602 21.0402C17.1602 21.6802 16.6402 22.2002 15.9868 22.2002C15.3468 22.2002 14.8268 21.6802 14.8268 21.0402V15.1469C14.8268 14.5056 15.3468 13.9736 15.9868 13.9736C16.6402 13.9736 17.1602 14.5056 17.1602 15.1469V21.0402Z' fill='white' /></svg>");
  position: absolute;
  left: 27px;
  padding-top: 10px !important;
}

.Toastify__toast-body {
  margin-left: 60px !important;
}

.Toastify__toast > button > svg {
  display: none;
}
