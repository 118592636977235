/* Style the tooltip with the gradient */
.react-joyride__tooltip {
  background: linear-gradient(to left, #335ef7, #5f82ff);
  padding-top: 20px;
  color: #ffff !important;
  border-radius: 16px !important;
  width: 264px !important;
  /* Add any other desired styles */
}

.react-joyride-button-next {
  background: transparent;
  padding: '0px 20px 10px 20px';
  font-size: '12px';
  font-weight: '700';
  color: 'white';
  border: 'none';
  border-radius: '0';
  outline: 'none';
}
