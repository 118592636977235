@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /*button {*/
  /*    @apply focus:outline-none focus-visible:ring-2 focus-visible:ring-brand-a1*/
  /*}*/

  input:not(.manual-outline),
  textarea:not(.manual-outline) {
    @apply focus:outline-none focus-visible:ring-1 focus-visible:ring-primary-500 focus-visible:bg-primary-100;
  }

  .react-select input {
    @apply focus-visible:ring-0;
  }
}

.fixed-breakpoints-width {
  @apply s:w-768px m:w-1024px l:w-1440px;
}

.header-width {
  @apply w-full s:w-704px m:w-944px l:w-1208px;
}

.category-selector-width {
  @apply s:w-768px m:w-1024px l:w-screen;
}

.slider-button {
  @apply absolute top-1/4;
}

.categories-selector-item {
  @apply flex w-full pl-4 pr-8 items-center justify-start text-body-12 m:text-body-14 text-left whitespace-normal;
}
.categories-bg {
  background: linear-gradient(90deg, #fafafa 0, #fafafa 25%, #fff 26%, #fff);
}

.segmented-option-border-fix:last-child {
  border-right-width: 1px !important;
}

input:checked + div {
  @apply border-primary-500 bg-primary-500;
}
input:checked + div svg {
  @apply block text-white;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

/*.nc-gradient-brand {*/
/*    @apply bg-gradient-to-r from-brand-a3 to-brand-a1*/
/*}*/

.ios-height-hack {
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
}

.cookie-policy:hover {
  text-decoration: underline;
}

.infinite-scroll-container > div:first-child {
  width: 100%
}
